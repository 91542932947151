<div class="search-container" [class.opened]="results">
    <div class="search-input">
        <input
          id="searchInput"
            #searchInput
            [formControl]="searchControl"
            (blur)="blur()"
            autofocus
            placeholder="{{ 'shared.miscellaneous.search-dots' | transloco }}"
        />
    </div>
    <span class="icon-container" (click)="clear()">
        <i class="head-nav-icon icon a-icon a-icon--close"></i>
    </span>
</div>
@if (results || isLoading) {
    <div (clickOutside)="closeSearchField()" class="search-results-container">
        @if (!isLoading) {
            <div class="grid">
                @for (category of categories; track category) {
                    <div class="col">
                        <p class="title">{{ category.name | transloco }}</p>
                        @for (res of results[category.name]; track res) {
                            <a
                                class="item"
                                (click)="onNavigate()"
                                routerLink="/{{ category.key }}/details/{{ res.id }}"
                            >
                                <span class="main">{{ res.title }}</span>
                                <span class="sub">{{ res.subtitle }}</span>
                            </a>
                        }
                        @if (!results[category.name]?.length) {
                            <p>{{ 'shared.miscellaneous.no-matches' | transloco }}</p>
                        }
                    </div>
                }
            </div>
        }
        @if (isLoading) {
            <div class="spinner flex-center">
                <mat-spinner class="main-spinner" diameter="200" mode="indeterminate"></mat-spinner>
            </div>
        }
    </div>
}
