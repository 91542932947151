<mat-form-field
    class="mat-custom w-100"
    [appearance]="isOutlined ? 'outline' : 'fill'"
    [subscriptSizing]="dynamicSubscriptSizing ? 'dynamic' : 'fixed'"
    [ngStyle]="isOutlined ? { height: '36px' } : {}"
>
    <mat-label>{{ placeholder }}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [disabled]="isDisabled">
        <input matStartDate formControlName="start" placeholder="Start date" readonly [disabled]="isDisabled" />
        <input matEndDate formControlName="end" placeholder="End date" readonly [disabled]="isDisabled" />
    </mat-date-range-input>
    @if (showIcon) {
        <mat-datepicker-toggle matIconSuffix [for]="picker" disabled></mat-datepicker-toggle>
    } @else {
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    }
    <mat-date-range-picker #picker [calendarHeaderComponent]="header"></mat-date-range-picker>

    @if (range.controls.start.hasError('matStartDateInvalid')) {
        <mat-error>Invalid start date</mat-error>
    }
    @if (range.controls.end.hasError('matEndDateInvalid')) {
        <mat-error>Invalid end date</mat-error>
    }
</mat-form-field>
