import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { PersonalSettings } from '../../myceres/components/settingsv2/custom-filter/custom-filter';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private darkMode = new BehaviorSubject<boolean>(false);
    darkMode$ = this.darkMode.asObservable();
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        private overlay: OverlayContainer,
        private translateService: TranslocoService
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public applySettings(settings: PersonalSettings) {
        if (settings) {
            this.translateService.setActiveLang(settings.defaultLanguage);
            this.setTheme(settings);
        } else {
            this.translateService.setActiveLang('en');
            this.setSystemTheme();
        }
    }

    public setTheme(settings: PersonalSettings) {
        if (settings.defaultTheme === 'dark') {
            this.setDarkMode();
        } else if (settings.defaultTheme === 'light') {
            this.setLightMode();
        } else {
            this.setSystemTheme();
        }
    }

    public setDarkMode() {
        this.darkMode.next(true);
        this.renderer.setAttribute(document.body, 'data-theme', 'dark');
        this.overlay.getContainerElement().setAttribute('data-theme', 'dark');
    }

    public setLightMode() {
        this.darkMode.next(false);
        this.renderer.setAttribute(document.body, 'data-theme', '');
        this.overlay.getContainerElement().setAttribute('data-theme', '');
    }

    public setSystemTheme(): void {
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        prefersDarkMode ? this.setDarkMode() : this.setLightMode();
    }
}
